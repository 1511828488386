/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import DollarsAndSenseBlogRoutes from './dollars-sense-blog-routes';
import SMChallengesRoutes from './sm-challenges-routes';
import PolicyAndLaw from '../pages/policy-and-legislation';
import MirlRoutes from './mirl-routes';
import TrendingTOC from '../components/tocs/trendingTOC';
import Oops404Page from '../pages/oops-404';

export default function TrendingTopicsRoutes(props) {
    const root = props.match.path;
    const toc = () => (<TrendingTOC root={root} />);
    const crumbs = 'Trending Topics|';

    return (
      <div>
        <Switch>
          <Route
            path={`${root}/Blog`}
            render={(routeProps) => <DollarsAndSenseBlogRoutes {...routeProps} crumbs={crumbs} toc={toc()} />}
          />

          <Route
            path={`${root}/MIRL`}
            render={(routeProps) => <MirlRoutes {...routeProps} crumbs={crumbs} toc={toc()} />}
          />

          <Route
            path={`${root}/Policy`}
            render={(routeProps) => <PolicyAndLaw {...routeProps} crumbs={crumbs} toc={toc()} />}
          />

          <Route
            path={`${root}/SMChallenges`}
            render={(routeProps) => <SMChallengesRoutes {...routeProps} crumbs={crumbs} toc={toc()} />}
          />

          <Route render={() => <Oops404Page />} />
        </Switch>
      </div>
    );
}
