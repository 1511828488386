/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import TrainingRoutes from './training-routes';
import ServiceResourcesRoutes from './service-resources-routes';
import NavyResourceRoutes from './navy-resource-routes/navy-resource-routes';
import AirForceResource2Routes from './air-force-resource-routes/air-force-resource-2-routes';
import MosProductRoutes from './mos-product-routes';
import SplTOC from '../components/tocs/splTOC';
import Oops404Page from '../pages/oops-404';

export default function SplRoutes(props) {
    const root = props.match.path;
    const toc = () => (<SplTOC root={root} />);
    const crumbs = (props.crumbs ? `${props.crumbs},` : '') + `Service Providers and Leaders|${root}`;

    return (
      <div>
        <Switch>
          <Route
            path={`${root}/Training`}
            render={(routeProps) => <TrainingRoutes {...routeProps} crumbs={crumbs} toc={toc()} />}
          />
          <Route
            path={`${root}/Media`}
            render={() => <Redirect to="/ToolsAndAddRes/ShareableResources" />}
          />
          <Route
            path={`${root}/ServiceResources`}
            render={(routeProps) => <ServiceResourcesRoutes {...routeProps} crumbs={crumbs} toc={toc()} />}
          />

          <Route
            path={`${root}/NavyResource`}
            render={(routeProps) => <NavyResourceRoutes {...routeProps} crumbs={crumbs} toc={toc()} />}
          />

          <Route
            path={`${root}/AirForceResource`}
            render={(routeProps) => <AirForceResource2Routes {...routeProps} crumbs={crumbs} toc={toc()} />}
          />

          <Route path={`${root}/MOSProducts`} component={MosProductRoutes} />

          <Route render={() => <Oops404Page />} />
        </Switch>
      </div>
    );
}
