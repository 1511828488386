/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../../components/articlePage';
import ReactMarkdown from '../../components/markdown';
import Link from '../../components/link';

export default function HCFSASpecialEnrollment (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="dcfsa"
      >
        <ReactMarkdown
          source={`
## HCFSA Special Enrollment Period

The Federal Flexible Spending Account Program (FSAFEDS) will offer the first opportunity for eligible members of the uniformed services to enroll in a Health Care Flexible Spending Account (HCFSA) during a Special Enrollment Period.
`}
        />
        <div className="colored-textbox tan-bg">
          <div><b>The Special Enrollment Period (SEP) is from March 3-31, 2025.</b> To enroll during this time, go to <Link to="http://www.fsafeds.gov/">www.FSAFEDS.gov</Link>, and follow these instructions:</div>
        </div>
        <div className="colored-textbox light-tan-bg">
          <div>
            <ol>
              <li>Select “Qualifying Life Event” (QLE) from the Enroll drop down menu.</li>
              <li>Select the QLE titled “Special Enrollment Period for Members of the Uniformed Services March 3-31.”</li>
              <li>Enter the date you are enrolling as your QLE event date.</li>
              <li>Follow the remaining instructions.</li>
            </ol>
          </div>
        </div>

        <ReactMarkdown
          source={`
Your account will be activated within a few business days of your enrollment, and the “election effective date” will be updated to the date of the day after your enrollment. This means your entire allotment of funds will be available for use upon account activation.

You will have until Dec. 31, 2025 to use these funds. If you choose to reenroll during Federal Benefits Open Season, (typically mid-November through mid-December), you may carry over up to $660 in unused HCFSA funds to the next plan year.

**FSA Enrollment by Uniformed Service**
- Members of the uniformed services in the Army, Marine Corps, Navy, Air Force, Space Force and U.S. Public Health Service are eligible to sign up for an HCFSA during the SEP.
- Members of the Coast Guard and National Oceanic & Atmospheric Administration Commissioned Officer Corps will be eligible for both a DCFSA and an HCFSA for the first time during the SEP.

**Enrollment for future years will occur during the annual Federal Benefits Open Season.**
`}
        />
      </Page>
    );
}
